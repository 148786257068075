<template>
    <div>
        <div class="form-row">
            <div class="form-col">
                <p>This will add the Schedule a Tour widget that is configured to connect to your integration partner.</p>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";

export default {
    mixins: [ ModalNavigation ],
    components: { ModalFooter },
    emits: ['save-page-widget'],
    data: () => {
        return {
            isProcessing: false,
        }
    },
    methods: {
        save() {
            this.isProcessing = true;
            this.$emit('save-page-widget', {});
        },
    }
}
</script>
